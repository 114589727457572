import { useEffect } from 'react';

// scss
import globalStyle from '@/assets/styles/app.module.scss';

// components
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';
import Button from '@/features/reusablecomponents/buttons/Button';

// datas
import dataGlobal from '@/services/datas/fr-FR/global.json';

// utils
import { handleItemSelected } from '@/services/device/deviceHandleFunctions';
import {
    handleUpdateDeviceActivatedStatus,
    playSound,
} from '@/services/global/globalHandleFunctions';
import { Device } from '@/services/global/types';

// services
import ActionButton from '@/features/devices/tablets/components/actionButton/ActionButton';
import { useGameStore } from '@/store/gameStore';

import { ClickTetris } from '@/assets/sounds/soundsEffect';

import { useGame3Store } from '@/store/games/game3Store';
import { useOverlayStore } from '@/store/overlayStore';
import { useRoomStore } from '@/store/roomStore';
import { useTranslation } from 'react-i18next';
import type { GameItem } from '@/types/games/types';
import { emitSocketEvent } from '@/services/global/globalUtils';

const ChooseGlobalFormTetris: React.FC = () => {
    const roomName = useRoomStore.use.roomName();
    const gameStatus = useGameStore.use.gameStatus();
    const deviceColor = Device.red;
    const { t } = useTranslation(['global', 'actionContent']);

    const itemsSelected = useGame3Store.use.itemsSelected();
    const statusTetris = useGame3Store.use.statusTetris();
    const itemsFound = useGame3Store.use.itemsFound();
    const overlayType = useOverlayStore.use.overlayType();

    const itemsFoundIds = itemsFound.map((item) => item?.id);

    const isAllTetrisSelected =
        itemsSelected.emotion &&
        itemsSelected.tetriseric &&
        itemsSelected.tetrisfanny &&
        itemsSelected.tetrispaul;
    const isErrorOrSuccessTetrisSelected =
        overlayType === 'success' || overlayType === 'error';

    useEffect(() => {
        if (
            itemsSelected.tetriseric &&
            itemsSelected.tetrisfanny &&
            itemsSelected.tetrispaul
        ) {
            handleUpdateDeviceActivatedStatus(Device.red, true);
        }
    }, [itemsSelected, roomName]);

    const handleSelectGlobalFormTetris = (item: GameItem) => {
        handleItemSelected(item, 'emotion', gameStatus);
        handleUpdateDeviceActivatedStatus(Device.orange, true);
        handleUpdateDeviceActivatedStatus(Device.blue, true);
        handleUpdateDeviceActivatedStatus(Device.green, true);

        emitSocketEvent('send_update_status_tetris', {
            statusTetris: 'not-ready',
        });
        playSound(ClickTetris);
    };
    if (isErrorOrSuccessTetrisSelected) {
        if (statusTetris === 'waiting-validation') {
            return (
                <TabletScreen
                    typeBackground="circle"
                    step="action"
                    deviceColor={deviceColor}
                >
                    <ActionButton
                        title={
                            overlayType === 'success'
                                ? t('buttons.continue')
                                : t('buttons.retry')
                        }
                        action={
                            overlayType === 'success'
                                ? 'successValidation'
                                : 'startTetris'
                        }
                        type={overlayType}
                    />
                </TabletScreen>
            );
        }
        return (
            <TabletScreen
                typeBackground="circle"
                step="action"
                deviceColor={deviceColor}
            >
                <ActionButton
                    title={
                        overlayType === 'success'
                            ? t('buttons.next')
                            : t('buttons.retry')
                    }
                    action={
                        overlayType === 'success'
                            ? 'startTetris'
                            : 'tryAgainShapeSelection'
                    }
                    type={overlayType}
                />
            </TabletScreen>
        );
    }
    if (isAllTetrisSelected) {
        return (
            <div className={globalStyle.itemBoxContainer}>
                <TabletScreen
                    header={{
                        title: t(
                            `${gameStatus}.${deviceColor}.headerContent.confirm`,
                            { ns: 'actionContent' }
                        ),
                        text: '',
                    }}
                    typeBackground="circle"
                >
                    <div className={globalStyle.itemBoxContainer}>
                        <ActionButton
                            title={t('buttons.next')}
                            action="validationListTetris"
                            type="neutral"
                        />
                    </div>
                </TabletScreen>
            </div>
        );
    }

    return (
        <div className={globalStyle.itemBoxContainer}>
            <TabletScreen
                step="action"
                deviceColor={deviceColor}
                typeBackground="circle"
            >
                {!itemsSelected.emotion && (
                    <div className={globalStyle.itemBoxContainer}>
                        {dataGlobal.emotions.map((emotion) => {
                            if (itemsFoundIds.includes(emotion.id)) {
                                return null;
                            }
                            return (
                                <Button
                                    text={`${t(
                                        `${gameStatus}.tv.bodyContent.footer`,
                                        { ns: 'actionContent' }
                                    )} ${emotion.id}`}
                                    onClick={() => {
                                        handleSelectGlobalFormTetris(emotion);
                                    }}
                                    key={emotion.slug}
                                    backgroundColor="neutral"
                                />
                            );
                        })}
                    </div>
                )}
            </TabletScreen>
        </div>
    );
};

export default ChooseGlobalFormTetris;
