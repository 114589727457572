import ButtonsDirections from '@/features/devices/tablets/mediator/gamesComponents/ButtonsDirections';
import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import dataGlobal from '@/services/datas/fr-FR/global.json';
import datas from '@/services/datas/mediator/actionsGames.json';
import { resetItemsFound } from '@/services/datas/mediator/genericDatas.json';
import { useGameStore } from '@/store/gameStore';
import { useTranslation } from 'react-i18next';

import { useGame3Store } from '@/store/games/game3Store';
import { useGame5Store } from '@/store/games/game5Store';
import { useOverlayStore } from '@/store/overlayStore';
import { useRoomStore } from '@/store/roomStore';
import {
    errorValidation,
    handleTabletStatus,
    successValidation,
    validateGameAction,
    validationListTetris,
} from '@/services/device/deviceHandleFunctions';
import {
    handleCarouselValuesFromChoiceEmotion,
    handleGoodCombination,
    handleResetItemsFound,
    type ActionsGamesMapping,
} from '@/services/device/mediatorHandleFunctions';
import { useItemsFound } from '@/services/hooks/useItemFounds';
import { useItemsSelected } from '@/services/hooks/useItemSelected';
import {
    handleStartTetris,
    handleUpdateOverlayDescriptions,
} from '@/services/tv/tvHandleFunctions';
import {
    GAMESTATUS,
    type Behavior,
    type Emotion,
    type GameStatusActionWithoutGame5,
    type ItemsSelected,
} from '@/types/games/types';
import SocketService from '@/services/SocketService';
import { emitSocketEvent } from '@/services/global/globalUtils';
import type { Situation } from '@/types/global/types';
import { isObjectEmpty } from '@/services/device/deviceUtils';

const RenderComponentGamesActions: React.FC = () => {
    const socket = SocketService.getInstance().getSocket();
    const roomName = useRoomStore.use.roomName();
    const { t } = useTranslation('global');

    const gameStatus = useGameStore.use.gameStatus();
    const overlayType = useOverlayStore.use.overlayType();

    // From the JSON file actionsGames we retrieve the data of current Game, examples:
    // button texts, and the actions we want to execute which are stored in a nameFunctions array.
    const actionsGames: ActionsGamesMapping = datas;
    const btnActions = actionsGames[gameStatus].actions;

    const emotions = dataGlobal.emotions;

    const currentIndex = useGame5Store.use.currentIndex();
    const currentSituation = dataGlobal.GAME5ACTION.situations[currentIndex];

    // conditions
    const statusTetris = useGame3Store.use.statusTetris();
    const isTetrisStarted = statusTetris === 'start';

    const itemsSelected = useItemsSelected(
        gameStatus as GameStatusActionWithoutGame5
    );

    const itemsFound = useItemsFound(gameStatus as GameStatusActionWithoutGame5);

    const isGame1Action1SelectionFilled =
        itemsSelected?.emotion !== undefined && itemsSelected?.symbol !== undefined;
    const isGame1Action2SelectionFilled =
        itemsSelected?.emotion !== undefined &&
        itemsSelected?.strongEmotion !== undefined &&
        itemsSelected?.weakEmotion !== undefined;
    const isGame2SelectionFilled =
        !isObjectEmpty(itemsSelected?.emotion) &&
        !isObjectEmpty(itemsSelected?.work) &&
        !isObjectEmpty(itemsSelected?.family) &&
        !isObjectEmpty(itemsSelected?.sport) &&
        !isObjectEmpty(itemsSelected?.friends);
    const isGame3SelectionFilled =
        itemsSelected?.emotion !== undefined &&
        itemsSelected?.tetriseric !== undefined &&
        itemsSelected?.tetrispaul !== undefined &&
        itemsSelected?.tetrisfanny !== undefined &&
        !isTetrisStarted;
    const isGame4SelectionFilled =
        itemsSelected?.code && itemsSelected?.code.length > 0;

    const isCombinationFullfilled =
        isGame1Action1SelectionFilled ||
        isGame1Action2SelectionFilled ||
        isGame2SelectionFilled ||
        isGame3SelectionFilled ||
        isGame4SelectionFilled;

    const atLeastOneItemFound = itemsFound?.some((item) => item !== null) || false;

    const isGameAction =
        gameStatus === GAMESTATUS.GAME1ACTION1 ||
        gameStatus === GAMESTATUS.GAME1ACTION2 ||
        gameStatus === GAMESTATUS.GAME2ACTION ||
        gameStatus === GAMESTATUS.GAME3ACTION ||
        gameStatus === GAMESTATUS.GAME4ACTION ||
        gameStatus === GAMESTATUS.GAME5ACTION;
    const isOverlaySuccess = overlayType === 'success';
    const isOverlayError = overlayType === 'error';
    const isOverlay = isOverlayError || isOverlaySuccess;

    const isValidationSelection =
        isGameAction && !isOverlay && isCombinationFullfilled;

    const getSelection = (itemsSelected?: Emotion) => {
        switch (gameStatus) {
            case GAMESTATUS.GAME4ACTION: {
                const behaviors = t(`${gameStatus}.behaviors`, {
                    returnObjects: true,
                }) as Behavior[];
                const behaviorSelected = behaviors.find(
                    (behavior) => behavior.slug === itemsSelected?.slug
                );
                return behaviorSelected as Behavior;
            }
            case GAMESTATUS.GAME5ACTION:
                return currentSituation as Situation;
            default:
                return itemsSelected as Emotion;
        }
    };

    // Each action of handleClick corresponds to a function name retrieved from the nameFunctions array in the JSON file actionsGames
    const handleClick = (
        action: string,
        itemsSelected?: Emotion | ItemsSelected | Behavior
    ) => {
        switch (action) {
            case 'selectGoodCombination':
                handleGoodCombination(
                    getSelection(itemsSelected as Emotion),
                    gameStatus
                );
                break;
            case 'updateStatusTetrisToNotReady':
                emitSocketEvent('send_update_status_tetris', {
                    statusTetris: 'not-ready',
                });
                break;
            case 'updateCarouselValues':
                handleCarouselValuesFromChoiceEmotion(itemsSelected as Behavior);
                break;
            case 'validateSelection':
                validateGameAction({
                    itemsSelected: itemsSelected as ItemsSelected,
                    gameStatus: gameStatus as GameStatusActionWithoutGame5,
                });
                break;
            case 'validateListTetris':
                validationListTetris(gameStatus, itemsSelected as ItemsSelected);
                break;
            case 'passSuccessMessage':
                successValidation(
                    gameStatus,
                    itemsSelected as ItemsSelected,
                    itemsFound as Emotion[] | Behavior[],
                    roomName
                );
                break;
            case 'passErrorMessage':
                errorValidation(gameStatus);
                break;
            case 'passCongratsMessageGame3':
                if (statusTetris === 'waiting-validation') {
                    successValidation(
                        gameStatus,
                        itemsSelected as ItemsSelected,
                        itemsFound as Emotion[] | Behavior[],
                        roomName
                    );
                } else {
                    handleStartTetris();
                }
                break;
            case 'passErrorMessageGame3':
                if (statusTetris === 'waiting-validation') {
                    socket?.emit('send_update_status_tetris', {
                        roomName,
                        statusTetris: 'start',
                    });
                }
                break;
            case 'updateOverlayDescriptionsToEmpty':
                handleUpdateOverlayDescriptions([], 'neutral');
                break;
            case 'resetItemsFound':
                handleResetItemsFound(gameStatus);
                break;
            case 'completeTetris':
                emitSocketEvent('send_update_status_tetris', {
                    statusTetris: 'tetris-auto-completed',
                });
                break;
            case 'activateAllTablets':
                handleTabletStatus({
                    blue: true,
                    green: true,
                    orange: true,
                    red: true,
                });
                break;
            case 'activateOrangeTabletOnly':
                handleTabletStatus({
                    blue: false,
                    green: false,
                    orange: true,
                    red: false,
                });
                break;
            case 'activateRedTabletOnly':
                handleTabletStatus({
                    blue: false,
                    green: false,
                    orange: false,
                    red: true,
                });
                break;
            case 'activateGreenTabletOnly':
                handleTabletStatus({
                    blue: false,
                    green: true,
                    orange: false,
                    red: false,
                });
        }
    };

    /**
     * Renders a MediatorAction component to select good combinations with the given emotion for all games except Game5
     * Given that the 3 tetris game has 2 gameplay actions,the isTetrisStarted condition allows retrieving the correct
     * texts and functions based on the state of the tetris.
     */
    const renderMediatorAction = (emotion?: Emotion) => {
        return (
            <MediatorAction
                key={emotion ? emotion.slug : null}
                handleFunction={() => {
                    const nameFunctions = isTetrisStarted
                        ? btnActions?.completeTetris.nameFunctions
                        : btnActions?.selectGoodCombination.nameFunctions;
                    nameFunctions?.map((nameFunction) => {
                        handleClick(
                            nameFunction,
                            emotion ? emotion : (itemsSelected as ItemsSelected)
                        );
                    });
                }}
                title={
                    `${
                        isTetrisStarted
                            ? btnActions?.completeTetris.btnTitle
                            : btnActions?.selectGoodCombination.btnTitle
                    } ${emotion ? emotion?.title : ''}` || ''
                }
                description={
                    isTetrisStarted
                        ? btnActions?.completeTetris.description
                        : btnActions?.selectGoodCombination.description
                }
                displayingCondition={true}
                type="secondary"
            />
        );
    };

    return (
        <>
            {/* If items are selected or if the overlay is active,
           we display the next/continue buttons */}
            {isValidationSelection && (
                <MediatorAction
                    handleFunction={() => {
                        btnActions?.validateSelection.nameFunctions.map(
                            (nameFunction) => {
                                handleClick(nameFunction, itemsSelected);
                            }
                        );
                    }}
                    title={btnActions?.validateSelection.btnTitle || ''}
                    description={btnActions?.validateSelection.description}
                    displayingCondition={true}
                    type="secondary"
                />
            )}

            {isOverlay && (
                <MediatorAction
                    handleFunction={() => {
                        btnActions?.[
                            isOverlaySuccess ? 'overlaySuccess' : 'overlayError'
                        ].nameFunctions.map((nameFunction) => {
                            handleClick(nameFunction, itemsSelected);
                        });
                    }}
                    title={
                        btnActions?.[
                            isOverlaySuccess ? 'overlaySuccess' : 'overlayError'
                        ].btnTitle || ''
                    }
                    description={
                        btnActions?.[
                            isOverlaySuccess ? 'overlaySuccess' : 'overlayError'
                        ].description
                    }
                    displayingCondition={true}
                    type="secondary"
                />
            )}
            {/* If there is no success/error overlay and the items are not yet selected,
                then we display the game buttons that retrieve the correct combinations */}
            {!isOverlay && (
                <>
                    {/* For games 1, 3 (excluding Tetris status started), and 4, we want to be able to select the correct combination of an emotion of choice */}
                    {gameStatus === GAMESTATUS.GAME1ACTION1 ||
                    gameStatus === GAMESTATUS.GAME1ACTION2 ||
                    (gameStatus === GAMESTATUS.GAME3ACTION && !isTetrisStarted) ||
                    gameStatus === GAMESTATUS.GAME4ACTION
                        ? emotions.map((emotion) =>
                              itemsFound?.find((item) => item?.id === emotion.id)
                                  ? null
                                  : renderMediatorAction(emotion as Emotion)
                          )
                        : null}
                    {/* For game 2, the action is linear; we fill the combination with the currently displayed emotion on TV */}
                    {gameStatus === GAMESTATUS.GAME2ACTION &&
                        renderMediatorAction(itemsSelected?.emotion as Emotion)}
                    {/* For Tetris game 3, if the Tetris is currently scrolling, then the emotion has been previously chosen,
                        so we send the correct Tetris combination according to the emotion.
                        We also display the Tetris directional controls */}
                    {gameStatus === GAMESTATUS.GAME3ACTION && isTetrisStarted && (
                        <>
                            {renderMediatorAction(
                                itemsSelected?.emotion as Emotion
                            )}
                            <ButtonsDirections remoteName="tetris" />
                        </>
                    )}
                    {/* Carousel directional controls*/}
                    {gameStatus === GAMESTATUS.GAME4ACTION && (
                        <ButtonsDirections
                            remoteName={'carousel'}
                            itemsSelected={itemsSelected}
                        />
                    )}
                    {/* game5 => render good combination of current proposition   */}
                    {gameStatus === GAMESTATUS.GAME5ACTION &&
                        renderMediatorAction()}
                    <MediatorAction
                        handleFunction={() => {
                            socket?.emit('send_update_situation_game_5', {
                                roomName,
                                situationIndex: currentIndex - 1,
                            });
                        }}
                        title="Retour"
                        description="Revenir à la situation précédente"
                        displayingCondition={
                            gameStatus === GAMESTATUS.GAME5ACTION &&
                            currentIndex > 0
                        }
                        type="secondary"
                    />
                    {/* Reset items found */}
                    <MediatorAction
                        handleFunction={() => {
                            handleClick(resetItemsFound.nameFunction);
                        }}
                        title={resetItemsFound.btnTitle}
                        description={resetItemsFound.description}
                        displayingCondition={atLeastOneItemFound}
                        type="secondary"
                    />
                </>
            )}
        </>
    );
};

export default RenderComponentGamesActions;
