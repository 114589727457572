import i18n from '@/i18n';
import SocketService from '@/services/SocketService';
import { useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

// components
import BlueTablet from '@/features/devices/tablets/blue/BlueActionSwitch';
import GreenTablet from '@/features/devices/tablets/green/GreenActionSwitch';
import OrangeTablet from '@/features/devices/tablets/orange/OrangeActionSwitch';
import RedTablet from '@/features/devices/tablets/red/RedActionSwitch';
import Tv from '@/features/devices/tv/Tv';
import CreateRoom from '@/features/reusablecomponents/global/waitingPlayers/CreateRoom';

import { useDeveloperMode } from '@/services/hooks/useDeveloperMode';
import {
    resetItemsFoundByGameStatus,
    resetItemsSelectedByGameStatus,
    updateSelectedItemsByGameStatus,
} from '@/store/utils';
import { GAMESTATUS, type Behavior } from '@/types/games/types';
import { type DeviceColorWithTvAndMediator } from '@/types/global/types';
import {
    setDeviceActivatedStatus,
    setDeviceConnectedStatus,
} from './store/deviceStore';
import MediatorTablet from '@/features/devices/tablets/mediator/MediatorTablet';
import { handleGoToGameStatus } from '@/services/device/deviceHandleFunctions';
import {
    handleUpdateDeviceActivatedStatus,
    resetProtocol,
} from '@/services/global/globalHandleFunctions';
import { useDialStore } from '@/store/dialStore';
import { setItemsFoundGame1 } from '@/store/games/game1Store';
import {
    setItemsFoundGame2,
    setIsConfirmSelectionButton,
    setEmotionToItemsSelected,
} from '@/store/games/game2Store';
import {
    setItemsFoundGame3,
    setStatusTetris,
    setButtonTetrisOnClick,
} from '@/store/games/game3Store';
import {
    setItemsFoundGame4,
    setCarouselValues,
    useGame4Store,
    createInitialGame4Store,
} from '@/store/games/game4Store';
import { setCurrentSituation } from '@/store/games/game5Store';
import { setGameStatus, setIsAudioPlaying, setGameResult } from '@/store/gameStore';
import {
    setCurrentOverlayIndex,
    setCurrentOverlayDescriptions,
} from '@/store/overlayStore';
import { useRoomStore, setRoomName } from '@/store/roomStore';

const App: React.FC = () => {
    const socket = SocketService.getInstance().getSocket();
    const roomName = useRoomStore.use.roomName();
    const [room, setRoom] = useState(roomName);

    // If developper mode is on, it sets the room to devRoom then connects the devices
    useDeveloperMode();

    useEffect(() => {
        setRoom(roomName);
    }, [roomName]);

    useEffect(() => {
        socket.on('receive_start_game', (data) => {
            setDeviceConnectedStatus(data.color, true);

            if (data.color === 'mediator') {
                handleUpdateDeviceActivatedStatus(data.color, true);
                handleGoToGameStatus(GAMESTATUS.WELCOME_SCREEN);
            }
        });
        socket.on('receive_game_status', (data) => {
            setGameStatus(data.status);
        });
        socket.on('receive_device_activated_status', (data) => {
            setDeviceActivatedStatus(
                data.deviceColor as DeviceColorWithTvAndMediator,
                data.status
            );
        });
        socket.on('receive_reset', () => {
            resetProtocol();
        });
        socket.on('receive_is_audio_playing', (data) => {
            setIsAudioPlaying(data.isAudioPlaying);
        });
        socket.on('receive_update_overlay_index', (data) => {
            setCurrentOverlayIndex(data.overlayIndex);
        });
        socket.on('receive_update_overlay_descriptions', (data) => {
            setCurrentOverlayDescriptions(
                data.overlayDescriptions,
                data.overlayType
            );
            setGameResult('');
        });
        socket.on('receive_update_item_selected', (data) => {
            updateSelectedItemsByGameStatus(data);
        });
        socket.on('receive_update_item_found_game1action1', (data) => {
            setItemsFoundGame1(data.itemFound);
        });
        socket.on('receive_update_item_found_game1action2', (data) => {
            setItemsFoundGame1(data.itemFound);
        });
        socket.on('receive_update_item_found_game2', (data) => {
            setItemsFoundGame2(data.itemFound);
        });
        socket.on('receive_update_item_found_game3', (data) => {
            setItemsFoundGame3(data.itemFound);
        });
        socket.on('receive_update_item_found_game4', (data) => {
            setItemsFoundGame4(data.itemFound as Behavior);
        });
        socket.on('receive_update_situation_game_5', (data) => {
            setCurrentSituation(data.situationIndex);
        });
        socket.on('receive_update_is_confirm_selection_button', (data) => {
            setIsConfirmSelectionButton(data.status);
        });
        socket.on('receive_update_validation', (data) => {
            setGameResult(data.result);
        });
        socket.on('receive_update_status_tetris', (data) => {
            setStatusTetris(data.statusTetris);
        });
        socket.on('receive_update_language', (data) => {
            i18n.changeLanguage(data.language);
            const updatedStore = createInitialGame4Store();
            useGame4Store.setState(updatedStore);
        });
        socket.on('receive_dial_value', (data) => {
            useDialStore.setState({ value: data.value });
        });
        socket.on('receive_click_type_tetris', (data) => {
            setButtonTetrisOnClick({
                typeMouse: data.typeMouse,
                actionMouse: data.action,
            });
        });
        socket.on('receive_update_add_emotion_to_items_selected', () => {
            setEmotionToItemsSelected();
        });
        socket.on('receive_carousel_values', (data) => {
            setCarouselValues({ value: data.value, key: data.key });
        });
        socket.on('receive_reset_items_selected', (data) => {
            resetItemsSelectedByGameStatus(data);
        });
        socket.on('receive_reset_items_found', (data) => {
            resetItemsFoundByGameStatus(data);
        });
        return () => {
            socket.off('receive_start_game');
            socket.off('receive_game_status');
            socket.off('receive_device_activated_status');
            socket.off('receive_reset');
            socket.off('receive_is_audio_playing');
            socket.off('receive_update_overlay_index');
            socket.off('receive_update_overlay_descriptions');
            socket.off('receive_update_item_selected');
            socket.off('receive_update_item_found_game1action1');
            socket.off('receive_update_item_found_game1action2');
            socket.off('receive_update_item_found_game2');
            socket.off('receive_update_item_found_game3');
            socket.off('receive_update_item_found_game4');
            socket.off('receive_update_situation_game_5');
            socket.off('receive_update_is_confirm_selection_button');
            socket.off('receive_update_validation');
            socket.off('receive_update_status_tetris');
            socket.off('receive_update_language');
            socket.off('receive_dial_value');
            socket.off('receive_click_type_tetris');
            socket.off('receive_update_add_emotion_to_items_selected');
            socket.off('receive_carousel_values');
            socket.off('receive_reset_items_selected');
            socket.off('receive_reset_items_found');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const router = createBrowserRouter([
        {
            path: '/tv',
            element: room ? (
                <Tv />
            ) : (
                <CreateRoom type="tv" onRoomIdChosen={setRoomName} />
            ),
        },
        {
            path: '/red-tablet',
            element: room ? (
                <RedTablet />
            ) : (
                <CreateRoom type="red" onRoomIdChosen={setRoomName} />
            ),
        },
        {
            path: '/blue-tablet',
            element: room ? (
                <BlueTablet />
            ) : (
                <CreateRoom type="blue" onRoomIdChosen={setRoomName} />
            ),
        },
        {
            path: '/green-tablet',
            element: room ? (
                <GreenTablet />
            ) : (
                <CreateRoom type="green" onRoomIdChosen={setRoomName} />
            ),
        },
        {
            path: '/orange-tablet',
            element: room ? (
                <OrangeTablet />
            ) : (
                <CreateRoom type="orange" onRoomIdChosen={setRoomName} />
            ),
        },
        {
            path: '/mediator-tablet',
            element: room ? (
                <MediatorTablet />
            ) : (
                <CreateRoom type="mediator" onRoomIdChosen={setRoomName} />
            ),
        },
    ]);

    return <RouterProvider router={router} />;
};

export default App;
