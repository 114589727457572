import { useGame2Store } from '@/store/games/game2Store';
import { useEffect } from 'react';
import dataGlobal from '@/services/datas/fr-FR/global.json';
import { handleUpdateEmotionOnItemSelected } from '../device/deviceHandleFunctions';
import type { Emotion } from '@/types/games/types';

export default function useGame2UpdateEmotionOnItemSelected(): void {
    const itemsSelected = useGame2Store.use.itemsSelected();

    useEffect(() => {
        const index = dataGlobal.emotions.findIndex(
            (emotion) => emotion.id === itemsSelected.emotion?.id
        );
        handleUpdateEmotionOnItemSelected(dataGlobal.emotions[index] as Emotion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
