import i18n from '@/i18n';
import headerDatas from '@/services/datas/ja-JA/actionContent.json';
import { deepCopy } from '@/services/global/globalUtils';
import createSelectors from '@/store/selectors';
import type { Game4Store } from '@/types/games/game4/types';
import {
    type Carousel,
    type CarouselValues,
    type Game4Action,
} from '@/types/games/game4/types';
import { GAMESTATUS, type Behavior } from '@/types/games/types';
import { create } from 'zustand';

interface Game4ItemsSelected {
    item: Behavior | string;
    itemKey: keyof Game4Action;
}

// Cette fonction récupère dynamiquement les behaviors traduits
const getTranslatedBehaviors = (): Behavior[] =>
    i18n.t('GAME4ACTION.behaviors', { returnObjects: true }) as Behavior[];

export const createInitialGame4Store = (): Game4Store => {
    const behaviors = getTranslatedBehaviors();

    return {
        itemsSelected: { behavior: behaviors[0] } as Game4Action,
        listBehaviors: behaviors,
        itemsFound: Array(4).fill(null),
        headerContent: headerDatas[GAMESTATUS.GAME4ACTION].tv.headerContent,
        carouselValues: {
            flowDirection: 0,
            centerIndex: 0,
            leftIndex: behaviors.length - 1,
            rightIndex: 1,
        },
    };
};

const useGame4StoreBase = create<Game4Store>(() =>
    deepCopy(createInitialGame4Store())
);

export const useGame4Store = createSelectors(useGame4StoreBase);

export const selectItemsFoundGame4 = (): Behavior[] =>
    useGame4StoreBase.getState().itemsFound;

export const selectItemsSelectedGame4 = (): Game4Action =>
    useGame4StoreBase.getState().itemsSelected;

export const selectCarouselValues = (): CarouselValues =>
    useGame4StoreBase.getState().carouselValues;

export const selectListBehaviors = (): Behavior[] =>
    useGame4StoreBase.getState().listBehaviors;

export const setItemsSelectedGame4 = (data: Game4ItemsSelected): void => {
    useGame4StoreBase.setState((state) => ({
        itemsSelected: { ...state.itemsSelected, [data.itemKey]: data.item },
    }));
};

export const setItemsFoundGame4 = (behavior: Behavior): void => {
    const newItemsFound = deepCopy(useGame4StoreBase.getState().itemsFound);
    newItemsFound.splice(behavior.id - 1, 1, behavior);

    useGame4StoreBase.setState((state) => ({
        itemsFound: newItemsFound,
        listBehaviors: state.listBehaviors.map((behaviors) => ({
            ...behaviors,
            behaviorFound:
                behaviors.emotionId === behavior.id
                    ? true
                    : behaviors.behaviorFound,
        })),
    }));
};

export const setCarouselValues = (carousel: Carousel): void => {
    useGame4StoreBase.setState((state) => ({
        carouselValues: { ...state.carouselValues, [carousel.key]: carousel.value },
    }));
};

export const resetItemsSelectedGame4 = (): void => {
    useGame4StoreBase.setState((state) => ({
        itemsSelected: { ...state.itemsSelected, code: '' },
    }));
};

export const resetItemsFoundGame4 = (): void => {
    useGame4StoreBase.setState((state) => ({
        ...deepCopy(createInitialGame4Store()),
        headerContent: state.headerContent,
    }));
};
