import headerDatas from '@/services/datas/fr-FR/actionContent.json';
import { deepCopy } from '@/services/global/globalUtils';
import createSelectors from '@/store/selectors';
import {
    type ButtonTetris,
    type Game3Action,
    type Game3Store,
} from '@/types/games/game3/types';
import { GAMESTATUS, type Emotion } from '@/types/games/types';
import { create } from 'zustand';

interface ItemsSelected {
    item: Emotion | string;
    itemKey: keyof Game3Action;
}

export const INITIAL_GAME3_STORE: Game3Store = {
    itemsSelected: {} as Game3Action,
    itemsFound: [],
    headerContent: headerDatas[GAMESTATUS.GAME3ACTION].tv.headerContent,
    statusTetris: 'not-ready',
    buttonTetris: {
        typeMouse: undefined,
        actionMouse: undefined,
    },
};

const useGame3StoreBase = create(() => deepCopy(INITIAL_GAME3_STORE));

export const useGame3Store = createSelectors(useGame3StoreBase);

export const selectItemsFoundGame3 = (): Emotion[] => {
    return useGame3StoreBase.getState().itemsFound;
};

export const selectItemsSelectedGame3 = (): Game3Action => {
    return useGame3StoreBase.getState().itemsSelected;
};

export const selectStatusTetris = (): string => {
    return useGame3StoreBase.getState().statusTetris;
};

export const selectButtonTetris = (): ButtonTetris => {
    return useGame3StoreBase.getState().buttonTetris;
};

export const setItemsSelectedGame3 = (data: ItemsSelected): void => {
    useGame3StoreBase.setState((state) => ({
        itemsSelected: {
            ...state.itemsSelected,
            [data.itemKey]: data.item,
        },
    }));
};

export const setItemsFoundGame3 = (emotion: Emotion): void => {
    const newItemsFound = deepCopy(useGame3Store.getState().itemsFound);
    newItemsFound.splice(emotion.id - 1, 1, emotion);

    useGame3Store.setState({
        itemsFound: newItemsFound,
    });
};

export const setStatusTetris = (status: string): void => {
    useGame3StoreBase.setState({ statusTetris: status });
};

export const setButtonTetrisOnClick = (button: ButtonTetris): void => {
    useGame3StoreBase.setState({
        buttonTetris: button,
    });
};

export const resetItemsSelectedGame3 = (): void => {
    useGame3StoreBase.setState({
        itemsSelected: deepCopy(INITIAL_GAME3_STORE.itemsSelected),
    });
};

export const resetItemsFoundGame3 = (): void => {
    useGame3StoreBase.setState({
        itemsFound: deepCopy(INITIAL_GAME3_STORE.itemsFound),
    });
};
