import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import globalFR from '@/services/datas/fr-FR/global.json';
import actionContentFR from '@/services/datas/fr-FR/actionContent.json';
import overlayContentFR from '@/services/datas/fr-FR/overlayContent.json';
// KOREA
import globalKO from '@/services/datas/ko-KO/global.json';
import actionContentKO from '@/services/datas/ko-KO/actionContent.json';
import overlayContentKO from '@/services/datas/ko-KO/overlayContent.json';
// JAPA
import globalJA from '@/services/datas/ja-JA/global.json';
import actionContentJA from '@/services/datas/ja-JA/actionContent.json';
import overlayContentJA from '@/services/datas/ja-JA/overlayContent.json';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        ns: 'global',
        lng: 'fr-FR',
        resources: {
            'fr-FR': {
                global: globalFR,
                actionContent: actionContentFR,
                overlayContent: overlayContentFR,
            },
            'ko-KO': {
                global: globalKO,
                actionContent: actionContentKO,
                overlayContent: overlayContentKO,
            },
            'ja-JA': {
                global: globalJA,
                actionContent: actionContentJA,
                overlayContent: overlayContentJA,
            },
        },
        debug: import.meta.env.VITE_DEVELOPPER_MODE === 'true',
        fallbackLng: 'fr-FR',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
