// scss
import styles from '@/features/devices/tablets/components/game1action2/strongWeakEmotion.module.scss';

// components
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';

// datas
import dataGlobal from '@/services/datas/fr-FR/global.json';

// utils
import {
    handleItemSelected,
    handleTabletStatus,
} from '@/services/device/deviceHandleFunctions';
import {
    Device,
    type Emotion,
    type EmotionSecondary,
} from '@/services/global/types';

// services
import { Stars } from '@/assets/sounds/soundsEffect';
import StrongWeakEmotion from '@/features/devices/tablets/components/game1action2/StrongWeakEmotion';
import { playSound } from '@/services/global/globalHandleFunctions';
import { useGameStore } from '@/store/gameStore';
import { useGame1Store } from '@/store/games/game1Store';
import { useEffect, useState } from 'react';
import { GAMESTATUS, type GameItem } from '@/types/games/types';

interface ChooseIntensityEmotionProps {
    intensity: 'weakEmotion' | 'strongEmotion';
}
const ChooseIntensityEmotion: React.FC<ChooseIntensityEmotionProps> = ({
    intensity,
}) => {
    const itemsSelected = useGame1Store.use.itemsSelected().action2;
    const gameStatus = useGameStore.use.gameStatus();
    const deviceColor = intensity === 'weakEmotion' ? Device.orange : Device.green;

    const handleClickBox = (item: GameItem) => {
        handleItemSelected(item, intensity, gameStatus);
    };

    const [shuffledEmotions, setShuffledEmotions] = useState<EmotionSecondary[]>();

    useEffect(() => {
        const shouldActivateRed =
            itemsSelected &&
            Object.hasOwn(itemsSelected, 'emotion') &&
            Object.hasOwn(itemsSelected, 'weakEmotion') &&
            Object.hasOwn(itemsSelected, 'strongEmotion');

        if (gameStatus === GAMESTATUS.GAME1ACTION2) {
            handleTabletStatus({
                green: true,
                orange: true,
                red: shouldActivateRed ?? false,
                blue: true,
            });
        }
    }, [itemsSelected, gameStatus]);

    useEffect(() => {
        const intensityMap = {
            weakEmotion: dataGlobal.GAME1ACTION2.weakEmotion,
            strongEmotion: dataGlobal.GAME1ACTION2.strongEmotion,
        };
        setShuffledEmotions(intensityMap[intensity]);
    }, [intensity]);

    useEffect(() => {
        setShuffledEmotions((prevEmotions) => {
            if (prevEmotions) {
                return [...prevEmotions].sort(() => Math.random() - 0.5);
            }
            return [];
        });
    }, []);

    return (
        <TabletScreen
            step="action"
            deviceColor={deviceColor}
            typeBackground="empty"
        >
            <div className={styles.itemBoxContainer}>
                {shuffledEmotions?.map((emotion) => {
                    return (
                        <StrongWeakEmotion
                            key={emotion.id}
                            item={emotion as Emotion}
                            handleClick={() => {
                                handleClickBox(emotion);
                                playSound(Stars);
                            }}
                            isSelected={
                                emotion.id === itemsSelected?.[intensity]?.id
                            }
                        />
                    );
                })}
            </div>
        </TabletScreen>
    );
};

export default ChooseIntensityEmotion;
